function init_scroll() {
    if($("main > .banner").length > 0) {
        var $header = $("header.header");
        var $navbar = $header.find('.navbar');
        var topofDiv = $("main > .banner").offset().top; //gets offset of banner
        var height = ($("main > .banner").outerHeight() - $('nav.navbar').outerHeight()); //gets height of banner
        var $body = $('body');
        var win_width = $(window).outerWidth();

        var scrollTimeout;
        var throttle = 5;
        var is_animating_header = false;

        // HEADER
        var evaluate_header_color = function() {
            var scrolltop = $(window).scrollTop();

            // COLOR
            // ((total - actual) / total) * 100% = percentage number
            var scroll_percentage = 100 - (((topofDiv + height) - $(window).scrollTop()) / (topofDiv + height)) * 100;
            var alpha_value = 1;

            if(scroll_percentage <= 100) {
                alpha_value = scroll_percentage / 100;
            }

            $navbar.css('background', 'linear-gradient(to bottom left, rgba(0,116,190,'+alpha_value.toFixed(2)+'), rgba(0, 158, 196,'+alpha_value.toFixed(2)+'))');

            // SIZE
            if(is_animating_header != true) {
                is_animating_header = true;
                $brand = $navbar.find('.navbar-brand');
                if(win_width > 768) {
                  if(scrolltop > height) {
                      // Make header compact
                      if($brand.height() != 80) {
                          $brand.animate({
                              height: 80
                          }, 250, function() {
                              is_animating_header = false;
                          });
                      }
                  }
                  else {
                      if($brand.height() != 130) {
                          $brand.animate({
                              height: 130
                          }, 100, function() {
                              is_animating_header = false;
                          });
                      }
                  }
                }

                else {
                  if($brand.height() != 80) {
                      $brand.animate({
                          height: 80
                      }, 250, function() {
                          is_animating_header = false;
                      });
                  }
                }
            }

        };
        evaluate_header_color();

        // SCROLL DOWN
        var evaluate_scroll_down_size = function() {
            $outer_ball = $('.banner__down-button__outer');

            // Check if ball exists
            if($outer_ball.length < 1) {
                return;
            }

            var scrolltop = $(window).scrollTop();

            // ((total - actual) / total) * 100% = percentage number
            var scroll_percentage = 100 - (((topofDiv + height) - $(window).scrollTop()) / (topofDiv + height)) * 100;
            var scale_value = 1;

            if(scroll_percentage <= 100) {
                scale_value = scroll_percentage / 100;
            }

            scale_value = scale_value + 1;

            $outer_ball.css('transform', "translate(-50%, -50%) scale("+scale_value.toFixed(2)+")");
        };
        evaluate_scroll_down_size();

        $(window).on('scroll', function () {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(function () {
                    evaluate_header_color();
                    evaluate_scroll_down_size();
                    scrollTimeout = null;
                }, throttle);
            }
        });

        $(window).on('resize', function() {
          // Update used width
          win_width = $(window).outerWidth();
          evaluate_header_color();
          evaluate_scroll_down_size();
        });
    }
    else {
      var $header = $("header.header");
      var $navbar = $header.find('.navbar');
      $navbar.css('background', 'linear-gradient(to bottom left, rgba(0,116,190,1), rgba(0, 158, 196,1))');

      $brand = $navbar.find('.navbar-brand');
      $brand.css('height', '80px');
    }
}
