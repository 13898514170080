jQuery(document).ready(function($) {
    if(myLazyLoad) {
        myLazyLoad.update();
    }

    // Inits here
    init_header();
    init_scroll();
    init_gallery();
    init_banner();
});
